<template>
  <div style="display:flex; width: 100%">
    <v-col cols="12" sm="3">
      <v-autocomplete
          :label="$t('country', {r:''})"
          :items="dropdownCountriesIso"
          v-model="selectedCountry"
          @change="onNationsChange"
          hide-details="auto"
          autocomplete="nope"
          outlined
          clearable
          dense
      ></v-autocomplete>
    </v-col>
    <v-col cols="12" sm="3">
      <v-autocomplete
          :label="$t('region')"
          :items="dropdownRegions"
          v-model="selectedRegion"
          :disabled="disabledRegionsSelection"
          @change="onRegionsChange"
          outlined
          autocomplete="nope"
          hide-details="auto"
          clearable
          dense
      ></v-autocomplete>
    </v-col>
    <v-col cols="12" sm="3">
      <v-autocomplete
          :label="$t('province')"
          :items="dropdownProvinces"
          :disabled="disabledProvincesSelection"
          v-model="selectedProvince"
          @change="onProvincesChange"
          outlined
          autocomplete="nope"
          hide-details="auto"
          multiple
          clearable
          dense
      ></v-autocomplete>
    </v-col>
    <v-col cols="12" sm="3">
      <v-text-field 
        v-model="selectedCity" 
        outlined 
        clearable 
        dense 
        hide-details="auto" 
        @keyup.enter="onCityChange"
        @change="onCityChange"
        autocomplete="nope" 
        :label="$t('city')"
      ></v-text-field>
    </v-col>
  </div>
</template>

<script>
import {call, get} from 'vuex-pathify';

export default {
  name: "GeonameSelector",
  props: {
    country : { type: String, required: false, default: null },
    region : { type: String, required: false, default: null },
    province : { type: Array, required: false, default: null },
    city : { type: String, required: false, default: null },
  },
  data: () => ({
    selectedCountry: null,
    selectedRegion: null,
    selectedProvince: null,
    selectedCity: '',
    disabledCountriesSelection: false,
    disabledRegionsSelection: true,
    disabledProvincesSelection: true,
  }),
  computed: {
    dropdownCountriesIso: get("geoname/dropdownCountriesIso"),
    dropdownRegions: get("geoname/dropdownRegions"),
    dropdownProvinces: get("geoname/dropdownProvinces")
  },
  mounted() {
    if (!this.dropdownCountriesIso.length) this.apiGeonameCountryGet().then(() => this.$emit('geonameLoaded'));
    else this.$emit('geonameLoaded');

    
    this.selectedCountry = this.country;
    this.selectedRegion = this.region;
    this.selectedProvince = this.province;
    this.selectedCity = this.city;

  },
  methods: {
    onNationsChange() {
      if (!!this.selectedCountry) {
        this.disabledRegionsSelection = false;
        this.apiGeonameCountryIsocodeRegionsGet(this.selectedCountry)
    .then(() => this.$emit('geonameLoaded'));
      } else {
        this.disabledRegionsSelection = true;
        this.disabledProvincesSelection = true;
        this.selectedProvince = null;
        this.selectedRegion = null;
      }
      this.emitUpdate();
    },

    onRegionsChange() {
      if (!!this.selectedRegion) {
        this.disabledProvincesSelection = false;
        this.apiGeonameRegionIsocodeProvincesGet(this.selectedRegion)
        .then(() => this.$emit('geonameLoaded'));
      } else {
        this.disabledProvincesSelection = true;
        this.selectedProvince = null;
      }
      this.emitUpdate();
    },

    onProvincesChange() {
      this.emitUpdate();
    },

    onCityChange() {
      this.$emit("update:city", this.selectedCity);
    },

    emitUpdate() {
      this.$emit("update:country", this.selectedCountry);
      this.$emit("update:region", this.selectedRegion);
      this.$emit("update:province", this.selectedProvince);
      this.$emit("update:city", this.selectedCity);
    },

    clear() {
      this.selectedCountry = null;
      this.selectedRegion = null;
      this.selectedProvince = null;
      this.selectedCity = null;
      this.disabledRegionsSelection = true;
      this.disabledProvincesSelection = true;
      this.emitUpdate();
    },
    ...call("geoname",
        ["apiGeonameCountryGet",
          "apiGeonameCountryIsocodeRegionsGet",
          "apiGeonameRegionIsocodeProvincesGet"])
  }
}
</script>
