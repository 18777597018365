<template>
  <v-card>
    <v-form>
      <v-card-text >
        <v-row>
          <v-col cols="12" sm="2">
            <v-autocomplete
              v-model="filters.customerId"
              :items="customers"
              :label="$t('customer')"
              multiple
              chips
              deletable-chips
              autocomplete="nope"
              hide-details="auto"
              dense
              clearable
              outlined
            ></v-autocomplete>
          </v-col>
          <!-- <v-col cols="12" sm="2">
            <v-autocomplete v-model="filters.tag" :items="dropdownTags" label="TAGS" multiple chips deletable-chips hide-details="auto" dense clearable autocomplete="nope" outlined></v-autocomplete>
          </v-col> -->
          <v-col cols="12" sm="2">
            <v-autocomplete
              v-model="filters.parentCode"
              :items="parents"
              :label="$t('parent')"
              multiple
              chips
              deletable-chips
              autocomplete="nope"
              hide-details="auto"
              dense
              clearable
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2" v-if="showSubAgent">
            <v-autocomplete
              v-model="filters.subagentId"
              :items="agents"
              :label="$t('subAgent')"
              multiple
              chips
              deletable-chips
              autocomplete="nope"
              hide-details="auto"
              dense
              clearable
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete
              v-model="filters.agentId"
              :items="agents"
              :label="$t('agent')"
              multiple
              chips
              deletable-chips
              autocomplete="nope"
              hide-details="auto"
              dense
              clearable
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete
              v-model="filters.supervisorAgentId"
              :items="agents"
              :label="$t('supervisorAgent')"
              multiple
              chips
              deletable-chips
              hide-details="auto"
              dense
              clearable
              autocomplete="nope"
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete
              v-model="filters.chiefAgentId"
              :items="agents"
              :label="$t('chiefAgent')"
              multiple
              chips
              deletable-chips
              hide-details="auto"
              dense
              clearable
              autocomplete="nope"
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete
              v-model="filters.commercialSpecialistId"
              :items="commercialSpecialists"
              :label="$t('commercialSpecialist')"
              multiple
              chips
              deletable-chips
              hide-details="auto"
              dense
              autocomplete="nope"
              clearable
              outlined
            ></v-autocomplete>
          </v-col>
          
          <v-col cols="12" sm="2" class="align-center">
            <v-select
              v-model="filters.customerPurchaseCategoryId"
              :items="customerPurchaseCategoriesDropdown"
              :label="$t('purchaseCategory')"
              multiple
              chips
              hide-details="auto"
              dense
              autocomplete="nope"
              clearable
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" sm="2">
            <v-select v-model="filters.salesSectors" :items="dropdownSectors" :label="$t('sector')" multiple chips hide-details="auto" dense autocomplete="nope" clearable outlined></v-select>
          </v-col>
          <v-col cols="12" sm="2">
            <v-select v-model="filters.salesChannels" :items="dropdownChannels" :label="$t('channel')" multiple chips hide-details="auto" dense autocomplete="nope" clearable outlined></v-select>
          </v-col>
          <v-col cols="12" sm="2">
            <v-select v-model="filters.hasEngravers" :items="threeWays" :label="$t('hasEngravers')" hide-details="auto" dense autocomplete="nope" clearable outlined></v-select>
          </v-col>
          <v-col cols="12" sm="2">
            <v-select v-model="filters.active" dense outlined clearable hide-details="auto" autocomplete="nope" :items="dropdownActive" :label="$t('active')"></v-select>
          </v-col>
          <v-col cols="12" sm="6" style="display: flex; padding: 0;">
            <GeonameSelector
              @update:country="filters.geonameCountryCode = !!$event? [$event] : null"
              @update:region="filters.geonameRegionCode = !!$event? [$event] : null"
              @update:province="filters.geonameProvinceCode = !!$event? $event : null"
              ref="geonameSelector"
            ></GeonameSelector>
          </v-col>
          <v-col cols="12" sm="2">
            <DateFilter v-model="filters.lastOrderDate" :type="'to'" :onEnter="doApplyFilters" :label="$t('lastOrderDateInRange')" @change="doApplyFilters"></DateFilter>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete
              v-model="filters.customerStatisticalTagId"
              :items="dropdownGroupIdentifiers"
              :label="$t('groupIdentifier')"
              multiple
              chips
              deletable-chips
              hide-details="auto"
              dense
              clearable
              autocomplete="nope"
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete
                v-model="filters.orderStatusId"
                :items="dropdownOrderStatus"
                :label="$t('orderStatus')"
                multiple
                chips
                deletable-chips
                hide-details="auto"
                dense
                clearable
                autocomplete="nope"
                outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field dense outlined :label="$t('cutoffOrderDate')" v-model="date" @change="dateUpdate" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="date" no-title scrollable @change="dateUpdate">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false" v-t="'cancel'"></v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(date);doApplyFilters()" v-t="'confirm'"></v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
           <v-col cols="12" sm="1" offset="10">
            <v-btn class="float-right" @click="clearFilters" v-t="'clear'"></v-btn>
          </v-col>
          <v-col cols="12" sm="1">
            <v-btn block @click="doApplyFilters" v-t="'apply'"></v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import {call, get, sync} from "vuex-pathify";
import DatePickerField from "../utility/DatePickerField";
import GeonameSelector from "./GeonameSelector";
import DateFilter from "./DateFilter.vue";

export default {
  name: "CustomerMonitoringFilter",
  components: {
    DatePickerField,
    GeonameSelector,
    DateFilter
  },
  data: () => ({
    menu: false,  
    date: null,
    selectedCountry: null,
    selectedRegion: null,
    selectedProvince: null,
    customerSearchType: 'list',
    countries: [],
    country_regions: [],
    region_provinces: [],
    codeTo: '',
    codeFrom: '',
    orderDateFrom: null,
    orderDateTo: null,
    orderDateToDisabled: true,
    orderDateMenu: null,
    codeMenu: null,
    shipmentDateMenu: null,
    shipDateFrom: null,
    shipDateTo: null,
    shipDateToDisabled: true,
  }),
  computed: {
    families: get('families/dropdownFamilies'),
    customers: get('customers/dropdownCustomersWithoutPotential'),
    parents: get('customers/dropdownParents'),
    agents: get('agents/dropdownActiveAgents'),
    orderTypes: get('orders/dropdownOrderTypes'),
    commercialSpecialists: get('commercialSpecialists/dropdownCommercialSpecialists'),
    orderStatus: get('orders/dropdownOrderStatus'),
    zones: get('geoname/dropdownZones'),
    filters: sync('customerMonitoring/filters'),
    businessTypes: get('customers/dropdownBusinessTypes'),
    customerPurchaseCategoriesDropdown: get("customers/customerPurchaseCategoriesDropdown"),
    dropdownGroupIdentifiers: get('customers/dropdownGroupIdentifiers'),
    dropdownOrderStatus: get('orders/dropdownOrderStatus'),
    showSubAgent: get('tenantConfiguration/showSubAgent'),
    dropdownTags: get('tags/dropdownTags'),
    dropdownChannels: get("customers/dropdownChannels"),
    dropdownSectors: get("customers/dropdownSectors"),
    dropdownActive(){
      return [
        {text: this.$t('all'), value: null},
        {text: this.$t('active'), value: true},
        {text: this.$t('inactive'), value: false}
      ]
    },
    dropdownStatus(){
      return [
        {text: this.$t('all'), value: null},
        {text: this.$t('incomplete'), value: '"INCOMPLETO"'},
        {text: this.$t('canceled'), value: '"ANNULLATO"'},
        {text: this.$t('complete'), value: '"COMPLETO"'},
        {text: this.$t('processed'), value: '"PROCESSATO"'}
      ]
    },
    threeWays(){
      return [
        {text: this.$t('unset'), value: null},
        {text: this.$t('yes'), value: true},
        {text: this.$t('no'), value: false}
      ]
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.initFamilyCollection();
      this.initCustomerCollection();
      this.initAgentCollection();
      this.orderTypesGet();
      this.initCommercialSpecialistCollection();
      this.initZoneCollection();
      this.initBusinessTypeCollection();
      // this.clearedOrderDate();
      this.apiPurchaseCategoryGet();
      this.apiCustomerStatisticalTagGet();
      this.initOrderStatusCollection();
      this.initTagCollection();
      this.initChannelCollection();
      this.initSectorCollection();
    },
    dateUpdate() {
      let d = this.$moment(this.date).format('DD'),
          m = this.$moment(this.date).format('MM'),
          y = this.$moment(this.date).format('YYYY');
      this.filters.cutoffLastOrderDate = `${d}${m}${y}`;
    },
    doApplyFilters() {
      this.$emit('applyCustomerMonitoringFilter', this.filters);
    },
    clearFilters() {
      this.clearedCode();
      this.clearedShipDate();
      this.filters = {};
      this.$refs.geonameSelector.clear();
      this.$emit('clearFilterEvent');
    },
    clearedCode() {
      this.codeFrom = '';
      this.codeTo = '';
    },
    codePopoverChanged() {
      this.filters.code = this.codeFrom;
      if (this.codeTo !== '') {
        this.filters.code = `${this.filters.code}..${this.codeTo}`;
      }
    },
    orderDatePopoverChanged(e) {
      this[e.field] = new Date(e.value);
      if (this.orderDateFrom === null) {
        this.orderDateToDisabled = true;
      } else {
        this.orderDateToDisabled = false;
        let orderDateFromDay = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(this.orderDateFrom);
        let orderDateFromMonth = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(this.orderDateFrom);
        let orderDateFromYear = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(this.orderDateFrom);
        this.filters.orderDate = `${orderDateFromDay}${orderDateFromMonth}${orderDateFromYear}`;
        if (this.orderDateTo !== null) {
          let orderDateToDay = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(this.orderDateTo);
          let orderDateToMonth = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(this.orderDateTo);
          let orderDateToYear = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(this.orderDateTo);
          this.filters.orderDate = `${this.filters.orderDate}..${orderDateToDay}${orderDateToMonth}${orderDateToYear}`;
        }
      }
    },
    clearedShipDate() {
      this.shipDateFrom = null;
      this.shipDateTo = null;
      this.shipDateToDisabled = true;
    },
    shipDatePopoverChanged(e) {
      this[e.field] = new Date(e.value);
      if (this.shipDateFrom === null) {
        this.shipDateToDisabled = true;
      } else {
        this.shipDateToDisabled = false;
        let shipDateFromDay = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(this.shipDateFrom);
        let shipDateFromMonth = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(this.shipDateFrom);
        let shipDateFromYear = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(this.shipDateFrom);
        this.filters.estimatedDeliveryDate = `${shipDateFromDay}${shipDateFromMonth}${shipDateFromYear}`;
        if (this.shipDateTo !== null) {
          let shipDateToDay = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(this.shipDateTo);
          let shipDateToMonth = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(this.shipDateTo);
          let shipDateToYear = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(this.shipDateTo);
          this.filters.estimatedDeliveryDate = `${this.filters.estimatedDeliveryDate}..${shipDateToDay}${shipDateToMonth}${shipDateToYear}`;
        }
      }
    },
    mandatoryShippingChanged(e) {
      this.filters[e.field] = e.value;
    },
    changedGeonameSelector(e) {
    },
    switchCustomerSearchType() {
      this.customerSearchType = this.customerSearchType === 'list' ? 'string' : 'list';
      if (this.customerSearchType === 'string') {
        this.filters.customerId = [];
      } else if (this.customerSearchType === 'list') {
        this.filters.customerSearchString = null;
      }
    },
    ...call('families', ['initFamilyCollection']),
    ...call('customers/*'),
    ...call('agents', ['initAgentCollection']),
    ...call('orders', ['orderTypesGet', 'initOrderStatusCollection']),
    ...call('commercialSpecialists', ['initCommercialSpecialistCollection']),
    ...call('geoname', ['initZoneCollection']),
    ...call('statistics', ['apiOrderStatisticalTagGet']),
    ...call('tags', ['initTagCollection']),
  }
}
</script>
